<script>
	import { afterUpdate, getContext } from "svelte";
	import Week from "./Week.svelte";
	import { localeContext } from "@xbs/lib-scheduler";

	export let model = {};
	export let selected;

	let table;
	let scrollWidth;
	const dateFormat =
		getContext(localeContext).getGroup("scheduler")("monthFormat");

	const templates = getContext("schedulerTemplates");

	$: ({ weekDays, monthTable, calendars, config } = model);

	afterUpdate(() => {
		scrollWidth = table.offsetWidth - table.clientWidth + 1.5;
	});

</script>

<div class="wx-event-calendar-layout">
	<div class="wx-event-calendar-weekdays">
		{#each weekDays as day}
			<div class="wx-event-calendar-weekday">
				<svelte:component
					this={templates.header}
					date={day.label}
					{dateFormat} />
			</div>
		{/each}
		{#if scrollWidth}
			<div style="width:{scrollWidth}px" />
		{/if}
	</div>
	<div class="wx-event-calendar-table" bind:this={table}>
		{#each monthTable as week}
			<Week {selected} {week} {calendars} {config} on:action />
		{/each}
	</div>
</div>

<style>
	.wx-event-calendar-layout {
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		color: var(--wx-color-font);
	}

	.wx-event-calendar-weekdays {
		display: flex;
		border-bottom: var(--wx-border);
		margin-left: -1px;
		color: var(--wx-color-font-alt);
	}

	.wx-event-calendar-weekday {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-shrink: 0;
		text-transform: capitalize;
	}

	.wx-event-calendar-table {
		flex: 1;
		overflow: auto;
	}</style>
