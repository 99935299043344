<script>
	import DayEvent from "./DayEvent.svelte";
	import { isSameDay, isToday } from "date-fns";
	import MinuteLine from "../MinuteLine.svelte";
	import { setTimeFromDate } from "@xbs/lib-scheduler";

	export let timeScale = [];
	export let events = [];
	export let cellCss;
	export let date;
	export let selected;
	export let calendars = [];
	export let calculateMinutesLinePosition;
	$: sorted = events.filter(e =>
		isSameDay(e.eventData.start_date, date.value)
	);
	$: today = isToday(date.value);

</script>

<div class="wx-event-calendar-wrapprer">
	{#each timeScale as hourCell (hourCell.value)}
		<div
			class="wx-event-calendar-time {cellCss(setTimeFromDate(date.value, hourCell.label))}"
			data-cell="{date.value?.valueOf()}:{hourCell.value}"
			data-drag="both"
			class:wx-event-calendar-today={today} />
	{/each}
	{#each sorted as event (event.eventData.id)}
		<DayEvent {event} {calendars} {selected} />
	{/each}
	{#if today}
		<MinuteLine {calculateMinutesLinePosition} />
	{/if}
</div>

<style>
	.wx-event-calendar-wrapprer {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 0;
		height: 100%;
		position: relative;
		overflow: visible;
	}
	.wx-event-calendar-time {
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: var(--wx-border);
		border-bottom: var(--wx-border);
		flex-grow: 0;
		flex-shrink: 0;
		height: var(--wx-event-calendar_hour-cell-height);
	}

	.wx-event-calendar-today {
		background-color: var(--wx-background-alt);
	}</style>
