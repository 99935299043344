<script>
	import { onMount } from "svelte";

	export let calculateMinutesLinePosition;
	export let orientation = "horizontal";
	let pos = calculateMinutesLinePosition();
	onMount(() => {
		const interval = setInterval(() => {
			pos = calculateMinutesLinePosition();
		}, 1200);
		return () => clearInterval(interval);
	});

</script>

{#if pos}
	<div
		class="wx-event-calendar-time-line-wrapper-{orientation}"
		style="{orientation === 'horizontal' ? 'top' : 'left'}: {pos}">
		<div class="wx-event-calendar-time-circle-{orientation}" />
		<div class="wx-event-calendar-time-line-{orientation}" />
	</div>
{/if}

<style>
	.wx-event-calendar-time-line-wrapper-horizontal {
		position: absolute;
		display: flex;
		align-items: center;
		left: -2px;
		width: 100%;
		pointer-events: none;
	}

	.wx-event-calendar-time-line-wrapper-vertical {
		position: absolute;
		display: flex;
		justify-content: center;
		top: -2px;
		height: 100%;
		pointer-events: none;
	}
	.wx-event-calendar-time-circle-horizontal {
		background: var(--wx-color-danger);
		border-radius: 50%;
		content: "";
		position: absolute;
		height: 12px;
		margin-left: -6.5px;
		width: 12px;
		z-index: var(--wx-popup-z-index);
	}
	.wx-event-calendar-time-circle-vertical {
		background: var(--wx-color-danger);
		border-radius: 50%;
		content: "";
		position: absolute;
		height: 12px;
		margin-top: 2px;
		width: 12px;
	}

	.wx-event-calendar-time-line-horizontal {
		max-width: 100%;
		flex-grow: 1;
		z-index: var(--wx-popup-z-index);
		border-top: var(--wx-color-danger) solid 2px;
	}

	.wx-event-calendar-time-line-vertical {
		max-height: 100%;
		flex-grow: 1;
		border-left: var(--wx-color-danger) solid 2px;
	}</style>
