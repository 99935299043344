<script>
	import InDayEvent from "./InDayEvent.svelte";
	import MultiDayEvent from "./MultiDayEvent.svelte";
	import ShowAll from "./ShowAll.svelte";
	import { isToday } from "date-fns";

	export let week = {};
	export let calendars = [];
	export let selected;
	export let config;
	$: ({ days, inDayEvents, multiDayEvents, height } = week);

</script>

<div class="wx-event-calendar-calendar-week" style="height: {height}px">
	{#each days as day}
		<div
			class="wx-event-calendar-day {config.cellCss(day.value)}"
			data-cell={day.value?.valueOf()}
			class:wx-event-calendar-inactive={!day.activeMonth}
			class:wx-event-calendar-today={isToday(day.value)}
			data-drag="both">
			<div
				class="wx-event-calendar-date"
				data-cell={day.value?.valueOf()}
				data-day-toggle={true}>
				<div class="wx-event-calendar-date-label">{day.label}</div>
			</div>
			{#if day.showAll}
				<ShowAll {day} on:action />
			{/if}
		</div>
	{/each}

	{#each $inDayEvents as event (event.eventData.id)}
		<InDayEvent {event} {calendars} />
	{/each}

	{#each $multiDayEvents as event}
		<MultiDayEvent {event} {week} {calendars} {selected} />
	{/each}
</div>

<style>
	.wx-event-calendar-calendar-week {
		position: relative;
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		border-bottom: var(--wx-border);
		min-height: var(--wx-event-calendar_month-cell-min-height);
	}

	.wx-event-calendar-day {
		position: relative;
		border-right: var(--wx-border);
	}

	.wx-event-calendar-day:last-child {
		border-right: none;
	}

	.wx-event-calendar-date {
		height: 30px;
		text-align: right;
		margin: 0 10px;
		line-height: 30px;
		display: flex;
		align-items: center;
		justify-content: end;
		flex-shrink: 0;
	}

	.wx-event-calendar-date-label {
		cursor: pointer;
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}

	.wx-event-calendar-date-label:hover {
		background: var(--wx-background-hover);
	}
	.wx-event-calendar-inactive .wx-event-calendar-date {
		color: var(--wx-color-font-disabled);
	}
	.wx-event-calendar-today {
		background-color: var(--wx-background-alt);
	}</style>
