<script>
	import { getContext } from "svelte";

	import { localeContext } from "@xbs/lib-scheduler";
	import TimelineHeaderRowSpan from "./TimelineHeaderRowSpan.svelte";
	export let colsWidth;
	export let header;
	export let sectionWidth;
	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");

</script>

{#each header as row, index (index)}
	<tr class="wx-event-calendar-timeline-row">
		{#if !index}
			<th
				wx-fixed-left-col
				style="min-width: {sectionWidth}px; position: sticky;
				left: 0;
				z-index: 2;"
				rowspan={header.length}
				class="wx-event-calendar-timeline-header-placeholder wx-event-calendar-timeline-header-cell">
				{_('Assignees')}
			</th>
		{/if}

		<TimelineHeaderRowSpan header={row} {colsWidth} {index} />
	</tr>
{/each}

<style>
	.wx-event-calendar-timeline-row {
		border-bottom: var(--wx-border);
		display: table-row;
		flex-shrink: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		transition: 0.3s;
	}
	.wx-event-calendar-timeline-header-cell {
		padding: 3px;
		position: -webkit-sticky;
		position: sticky;
		height: 32px;
		z-index: 1;
		border-right: var(--wx-border);
		border-bottom: var(--wx-border);
		background-color: var(--wx-background);
		box-sizing: border-box;
		overflow: hidden;
		display: table-cell;
		color: var(--wx-color-font-alt);
	}
	.wx-event-calendar-timeline-header-placeholder {
		position: -webkit-sticky;
		position: sticky;
		left: 0;
		top: 0;
		z-index: 2;
		flex-shrink: 0;
		padding: 4px 7px;
		border-right: var(--wx-border);
		background-color: var(--wx-background);
		vertical-align: middle;
		font-weight: var(--wx-font-weight);
	}

	.wx-event-calendar-timeline-row {
		border-bottom: var(--wx-border);
		display: table-row;
		flex-shrink: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		transition: 0.3s;
	}</style>
