<script>
	import { uid } from "@xbs/lib-core-dom";
	import { onMount, createEventDispatcher } from "svelte";

	export let value = "";
	export let id = uid();
	export let readonly = false;
	export let focus = false;
	export let select = false;
	export let type = "text";
	export let placeholder = "";
	export let disabled = false;
	export let error = false;
	export let inputStyle = "";

	const dispatch = createEventDispatcher();

	let input;
	onMount(() => {
		// wait till the source click processing will end
		setTimeout(() => {
			if (focus && input) input.focus();
			if (select && input) input.select();
		}, 1);
	});

</script>

{#if type == 'password'}
	<input
		bind:value
		bind:this={input}
		{id}
		{readonly}
		{disabled}
		{placeholder}
		type="password"
		style={inputStyle}
		class:error
		on:input={() => dispatch('input', { value })} />
{:else if type == 'number'}
	<input
		bind:value
		bind:this={input}
		{id}
		{readonly}
		{disabled}
		{placeholder}
		type="number"
		style={inputStyle}
		class:error
		on:input={() => dispatch('input', { value })} />
{:else}
	<input
		bind:value
		bind:this={input}
		{id}
		{readonly}
		{disabled}
		{placeholder}
		class:error
		style={inputStyle}
		on:input={() => dispatch('input', { value })} />
{/if}

<style>
	input {
		display: block;
		width: var(--wx-input-width);
		height: var(--wx-input-height);
		max-width: 100%;
		padding: var(--wx-input-padding);
		outline: none;
		font-family: var(--wx-input-font-family);
		font-size: var(--wx-input-font-size);
		line-height: var(--wx-input-line-height);
		font-weight: var(--wx-input-font-weigth);
		text-align: var(--wx-input-text-align);
		color: var(--wx-input-font-color);
		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background: var(--wx-input-background);
		overflow: hidden;
		text-overflow: ellipsis;
	}
	input:focus {
		border: var(--wx-input-border-focus);
	}
	input::-moz-placeholder {
		color: var(--wx-input-placeholder-color);
	}
	input:-ms-input-placeholder {
		color: var(--wx-input-placeholder-color);
	}
	input::placeholder {
		color: var(--wx-input-placeholder-color);
	}

	input[disabled] {
		cursor: not-allowed !important;
		border: var(--wx-input-border-disabled);
		color: var(--wx-color-font-disabled);
		background: var(--wx-input-background-disabled);
	}
	input[disabled]::-moz-placeholder {
		color: var(--wx-color-font-disabled);
	}
	input[disabled]:-ms-input-placeholder {
		color: var(--wx-color-font-disabled);
	}
	input[disabled]::placeholder {
		color: var(--wx-color-font-disabled);
	}

	input.error {
		border-color: var(--wx-color-danger);
		color: var(--wx-color-danger);
	}</style>
