<script>
	import { getContext, createEventDispatcher } from "svelte";
	import { Modal, Field, Text, Area, Button } from "@xbs/svelte-wx";
	import { localeContext, uid } from "@xbs/lib-scheduler";
	import { form } from "@xbs/lib-svelte";
	import ColorPickerSchema from "../Controls/ColorPickerSchema.svelte";

	export let calendar = {};

	const _ = getContext(localeContext).getGroup("scheduler");
	const colors = getContext("schedulerColors");
	const dispatch = createEventDispatcher();
	let mode, title, button;
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const values = form({}, () => {});
	$: if (calendar) {
		mode = "update";
		button = _("Save");
		title = `${_("Edit")} ${_("Calendar")}`;
		values.reset({
			description: "",
			...calendar,
			color: calendar.color,
		});
	} else {
		mode = "add";
		button = _("Add");
		title = `${_("New")} ${_("Calendar")}`;
		values.reset({
			id: uid(),
			label: `${_("New")} ${_("Calendar")}`,
			description: "",
			color: $colors[0],
		});
	}

	function save() {
		dispatch("sidebarAction", {
			action: `save`,
			data: {
				action: `${mode}-calendar`,
				data: {
					id: $values.id,
					calendar: {
						...$values,
					},
				},
			},
		});
	}
	function cancel() {
		dispatch("sidebarAction", {
			action: `close`,
		});
	}

</script>

<Modal ok={save} buttons={[]} {cancel}>
	<div name="title" class="wx-event-calendar-title">
		<span> {title} </span>
		<i class="wxi wxi-close" on:click={cancel} />
	</div>
	<div class="wx-event-calendar-wrapper">
		<Field label={_('Name')} position="top" let:id>
			<div class="wx-event-calendar-name">
				<Text
					{id}
					focus
					type="text"
					bind:value={$values.label}
					placeholder={_('Name')} />
				<div class="wx-event-calendar-colorpicker">
					<ColorPickerSchema
						colors={$colors}
						bind:color={$values.color} />
				</div>
			</div>
		</Field>
		<Field label={_('Description')} position="top" let:id>
			<Area
				{id}
				bind:value={$values.description}
				placeholder={_('Description')} />
		</Field>
	</div>
	<div name="buttons" class="wx-event-calendar-button">
		<Button type="primary" click={save}>{button}</Button>
	</div>
</Modal>

<style>
	.wx-event-calendar-wrapper {
		width: 244px;
		margin-top: 25px;
	}
	.wx-event-calendar-wrapper :global(label) {
		display: flex;
		align-items: start;
	}
	.wx-event-calendar-name {
		display: flex;
		gap: 10px;
	}
	.wx-event-calendar-colorpicker {
		width: 70px;
	}
	.wx-event-calendar-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size-md);
		font-weight: var(--wx-font-weight-b);
		color: var(--wx-color-font);
	}

	.wxi-close {
		font-size: var(--wx-line-height);
		color: var(--wx-icon-color);
		cursor: pointer;
	}
	.wx-event-calendar-button {
		display: flex;
		justify-content: end;
	}</style>
