<script>
	import { onMount, getContext, onDestroy } from "svelte";

	let portal;
	export let theme = "";
	export let target = document.body;

	if (theme === "") theme = getContext("wx-theme");

	onMount(() => {
		target.appendChild(portal);
	});

	onDestroy(() => {
		if (portal && portal.parentNode) portal.parentNode.removeChild(portal);
	});

</script>

<div class="wx-clone">
	<div class="wx-{theme}-theme" bind:this={portal}>
		<slot />
	</div>
</div>

<style>
	.wx-clone {
		display: none;
	}</style>
