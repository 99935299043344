<script>
	import { getContext } from "svelte";
	import { uid, dateToString } from "@xbs/lib-core-dom";

	import Text from "./Text.svelte";
	import Dropdown from "./Dropdown.svelte";
	import Calendar from "./Calendar.svelte";

	export let value;
	export let id = uid();
	export let disabled = false;
	export let error = false;
	export let width = "unset";
	export let align = "start";
	export let placeholder = "";
	export let format = "%m/%d/%Y";
	export let buttons;

	const _ = getContext("wx-i18n").getRaw().calendar;
	let dateFormat =
		typeof format === "function" ? format : dateToString(format, _);
	let popup;

	function cancel() {
		popup = false;
	}

	function doChange(ev) {
		const v = ev.detail.value;
		value = v;
		// fire after on-click finished
		setTimeout(cancel, 1);
	}

</script>

<svelte:window on:scroll={cancel} />

<div
	class="datepicker"
	class:disabled
	class:error
	on:click={() => (popup = true)}>
	<Text
		value={value ? dateFormat(value) : value}
		{id}
		readonly={true}
		{disabled}
		{error}
		{placeholder}
		inputStyle="cursor: pointer; width: 100%; padding-right: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);" />

	<i class="icon wxi-calendar" />

	{#if popup && !disabled}
		<Dropdown {cancel} {width} {align} autoFit={!!align}>
			<Calendar {buttons} {value} on:change={doChange} />
		</Dropdown>
	{/if}
</div>

<style>
	.datepicker {
		position: relative;
		width: var(--wx-input-width);
	}
	.datepicker.disabled .icon {
		color: var(--wx-color-font-disabled);
	}
	.datepicker.error .icon {
		color: var(--wx-color-danger);
	}

	.icon {
		position: absolute;
		right: var(--wx-input-icon-indent);
		top: 50%;
		transform: translateY(-50%);
		font-size: var(--wx-input-icon-size);
		line-height: 1;
		width: var(--wx-input-icon-size);
		height: var(--wx-input-icon-size);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
		color: var(--wx-input-icon-color);
	}
	.icon:before {
		display: block;
	}</style>
