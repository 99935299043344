<script>
	import Editor from "./Editor.svelte";
	import UnassignedEvents from "./UnassignedEvents.svelte";

	export let event = {};
	export let editMode;
	export let editorShape = [];
	export let datepicker;
	export let model;
	export let unassignedToggle;

	let showEditor = true;

	function toggleSidebar(ev) {
		showEditor = ev.detail.show;
	}

</script>

<div
	class="wx-event-calendar-editor"
	class:show={showEditor}
	on:click|stopPropagation>
	{#if editMode}
		<Editor
			{unassignedToggle}
			{event}
			{editMode}
			{editorShape}
			{datepicker}
			{model}
			on:alert
			on:recurringAction
			on:action
			on:toggleSidebar={toggleSidebar} />
	{:else}
		<UnassignedEvents {model} on:toggleUnassigned />
	{/if}
</div>

<style>
	.wx-event-calendar-editor {
		padding: 12px;
		flex-basis: 0px;
		z-index: 3;
		position: relative;
		box-shadow: var(--wx-box-shadow);
		background: var(--wx-background);
		overflow-y: auto;
		overflow-x: hidden;
		transition: flex-basis 0.2s;
		height: 100%;
	}

	.wx-event-calendar-editor.show {
		flex-basis: 400px;
		width: 400px;
		max-width: 400px;
	}</style>
