<script>
	import { createEventDispatcher } from "svelte";

	export let day;

	const dispatch = createEventDispatcher();

	let style;

	$: {
		const top = `top:${day.showAll.top}px;`;
		const height = `height:${day.showAll.height}px;`;
		const lineHeight = `line-height:${day.showAll.height}px;`;

		style = `${top}${height}${lineHeight}`;
	}

	function showAll() {
		dispatch("action", {
			action: "set-mode",
			data: { value: "day" },
		});
		dispatch("action", {
			action: "set-date",
			data: { value: day.value },
		});
	}

</script>

<div class="wx-event-calendar-today" {style} on:click={showAll}>
	{`View all (${day.events} events)`}
</div>

<style>
	.wx-event-calendar-today {
		position: absolute;
		width: 100%;
		padding: 0 6px;
		font-weight: var(--wx-font-weight-md);
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}</style>
