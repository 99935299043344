<script>
	import { localeContext } from "@xbs/lib-scheduler";
	import { createEventDispatcher, getContext } from "svelte";
	import { Text } from "@xbs/svelte-wx";
	import Tabbar from "./Tabbar.svelte";
	import UnassignedList from "./UnassignedList.svelte";
	import UnassignedRecurringList from "./UnassignedRecurringList.svelte";

	export let model;

	let showSearch = false;
	let search = "";
	let activeTab;
	$: ({ unassigned, unassignedRecurring } = model);

	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	const dispatch = createEventDispatcher();

	function keyHandlers(ev) {
		if (ev.key === "Escape") {
			close();
		}
	}

	function close() {
		dispatch("toggleUnassigned");
	}

</script>

<svelte:window on:keydown={keyHandlers} />
<div class="wx-event-calendar-unassign-wrapper" wx-unassign-list>
	<div class="wx-event-calendar-editor-controls">
		<i
			class="wxi-angle-dbl-left wx-event-calendar-unassigned-icons"
			on:click={close} />
		<div class="wx-unassigned-text">{_('Unassigned events')}</div>

		<i
			class="wxi-search wx-event-calendar-unassigned-icons"
			on:click={() => (showSearch = !showSearch)} />
	</div>

	<div class="wx-tabbar-wrapper">
		<Tabbar
			bind:active={activeTab}
			tabs={[{ id: 'single', label: _('Single events') }, { id: 'recurring', label: _('Recurring events') }]} />
	</div>

	{#if showSearch}
		<div class="wx-event-calendar-unassigned-search">
			<Text
				focus
				bind:value={search}
				inputStyle="cursor: pointer; width: 100%; padding-left: calc(var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2);" />
			<i class="wxi-search wx-search-icon" />
		</div>
	{/if}
	<div class="wx-unassigned-events-header">
		<span>{_('Event name')}</span>
		<span>{_('End date')}</span>
	</div>
	{#if !activeTab || activeTab === 'single'}
		<UnassignedList events={$unassigned} {search} />
	{/if}
	{#if !activeTab || activeTab === 'recurring'}
		<UnassignedRecurringList {search} eventsGroup={unassignedRecurring} />
	{/if}
</div>

<style>
	.wx-event-calendar-unassign-wrapper {
		height: 100%;
		max-height: 100%;
		overflow-x: hidden;
		max-width: 100%;
		min-width: 0;
		display: flex;
		flex-direction: column;
	}
	.wx-event-calendar-unassigned-icons {
		cursor: pointer;
		color: var(--wx-icon-color);
		font-size: var(--wx-icon-size);
	}

	.wx-event-calendar-editor-controls {
		display: flex;
		margin-bottom: 15px;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		max-width: 100%;
		min-width: 0;
	}

	.wx-unassigned-text {
		font-weight: var(--wx-font-weight-md);
		flex-grow: 1;
		line-height: 0;
		margin-top: -1px;
		min-width: 0;
	}
	.wx-event-calendar-unassigned-search {
		position: relative;
		margin-bottom: 12px;
	}
	.wx-search-icon {
		position: absolute;
		left: var(--wx-input-icon-indent);
		top: 50%;
		transform: translateY(-50%);
		font-size: var(--wx-input-icon-size);
		line-height: 1;
		width: var(--wx-input-icon-size);
		height: var(--wx-input-icon-size);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		-webkit-user-select: none;
		   -moz-user-select: none;
		    -ms-user-select: none;
		        user-select: none;
		color: var(--wx-input-icon-color);
	}
	.wx-unassigned-events-header {
		display: flex;
		justify-content: space-between;
		padding: 6px 10px;
		background-color: var(--wx-background-alt);
		font-size: var(--wx-font-size-sm);
	}

	.wx-tabbar-wrapper {
		margin-bottom: 20px;
	}</style>
