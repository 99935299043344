<script>
	import AgendaDay from "./AgendaDay.svelte";
	import { getContext } from "svelte";
	import { localeContext } from "@xbs/lib-scheduler";
	export let model = {};
	export let selected;
	$: ({ days, calendars, calculateMinutesLinePosition } = model);
	const _ = getContext(localeContext).getGroup("scheduler");

</script>

<div class="wx-event-calendar-agenda-wrapper">
	{#if days.length}
		{#each days as day (day.date)}
			<AgendaDay
				{day}
				{selected}
				{calendars}
				{calculateMinutesLinePosition} />
		{/each}
	{:else}
		<div class="wx-event-calendar-agenda-no-events">{_('No events')}</div>
	{/if}
</div>

<style>
	.wx-event-calendar-agenda-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
	.wx-event-calendar-agenda-no-events {
		align-self: center;
		margin-top: 20px;
	}</style>
